export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"}],"link":[{"rel":"preconnect","href":"https://fonts.googleapis.com"},{"rel":"preconnect","href":"https://fonts.gstatic.com","crossorigin":""},{"rel":"stylesheet","media":"print","onload":"this.onload=null;this.removeAttribute('media');","href":"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&display=swap"},{"rel":"dns-prefetch","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://a.storyblok.com"}],"style":[],"script":[{"src":"https://widget.trustpilot.com/bootstrap/v5/tp.widget.sync.bootstrap.min.js","type":"text/javascript","defer":true},{"innerHTML":"function OptanonWrapper(){};"},{"id":"ze-snippet","src":"https://static.zdassets.com/ekr/snippet.js?key=7e168789-330d-4979-b79f-28d83537059a","defer":true},{"hid":"gtmHead","innerHTML":"\n                    document.addEventListener('DOMContentLoaded', function() {\n                        setTimeout(function() {\n                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n                            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n                            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n                            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n                            })(window,document,'script','dataLayer','GTM-NLB4LZS');\n                        }, 5000);\n                    })\n                    "}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"externalRelAttribute":"","componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false