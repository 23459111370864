<template>
    <SbPage :blok="blok" />
    <!--modals-->
    <Component
        :is="globalStore.signInModal.component"
        v-if="globalStore && globalStore.signInModal"
        :global="globalStore.global.content"
        :blok="globalStore.signInModal"
    />
    <Component
        :is="globalStore.resetPasswordModal.component"
        v-if="globalStore && globalStore.resetPasswordModal"
        :global="globalStore.global.content"
        :blok="globalStore.resetPasswordModal"
    />
    <Component
        :is="globalStore.signUpModal.component"
        v-if="globalStore && globalStore.signUpModal"
        :global="globalStore.global.content"
        :blok="globalStore.signUpModal"
    />
</template>

<script setup>
import { useGlobalStore } from '../../_base/store/global';

defineProps({ blok: Object });
await $getSession();

// Constant variable for access to quote store
const globalStore = useGlobalStore();
</script>
